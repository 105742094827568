import { FC, PropsWithChildren } from "react";
import profileContext from "./context";
import useProfile from "../../hooks/useProfile";

const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
    const data = useProfile()

    return (
        <profileContext.Provider value={data}>
            {children}
        </profileContext.Provider>
    );
}

export default ProfileProvider;