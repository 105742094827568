import { useCallback } from "react"
import profileApi from "../api/profile"
import { IUser } from "../types/profile"
import useApiSource from "./useApiSource"
import useAsyncCallback from "./useAsyncCallback"
import toasts from "../components/toasts"

const useProfile = () => {
    const {data, setData} = useApiSource(profileApi.getProfile.bind(profileApi))
    const {data: approvedApps} = useApiSource(profileApi.getApprovedApps.bind(profileApi))
    const [updatingProfile, updateProfileFn] = useAsyncCallback(profileApi.updateProfile.bind(profileApi))
    const [updatingDeveloperProfile, updateDeveloperProfileFn] = useAsyncCallback(profileApi.updateDeveloperProfile.bind(profileApi))
    
    const updateProfile = useCallback((data: {profile?: IUser["profile"]}) => {
        updateProfileFn(data).then(() => {
            toasts.success("Profile updated")
            setData(old => old && ({...old, ...data}))
        })
    }, [updateProfileFn, setData])

    const updateDeveloperProfile = useCallback((data: IUser["developerProfile"]) => {
        updateDeveloperProfileFn(data).then(() => {
            setData(old => old && ({...old, developerProfile: data}))
        })
    }, [updateDeveloperProfileFn, setData])

    const updating = updatingProfile || updatingDeveloperProfile

    return {profile: data, updateProfile, updateDeveloperProfile, updating, approvedApps}
}

export default useProfile
