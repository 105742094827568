import { useCallback, useEffect, useState } from "react"
import appsApi from "../api/apps"
import { IPublicAppShort } from "../types/apps"

const usePublicApps = () => {
    const [data, setData] = useState<{
    data: IPublicAppShort[];
    page: number;
    hasMore: boolean;
}>()
    const [loadingNext, setLoadingNext] = useState(false)
    const apps = data?.data
    const hasMore = data?.hasMore

    useEffect(() => {
        appsApi.getApps({page: 1}).then(res => {
            setData({data: res.data, page: 1, hasMore: res.hasMore})
        })
    }, [])

    const loadNext = useCallback(() => {
        if (loadingNext) return
        if (!data?.hasMore) return
        setLoadingNext(true)
        appsApi.getApps({page: data.page + 1}).then(res => {
            setData(old => old && ({...old, data: [...old.data, ...res.data], page: res.page, hasMore: res.hasMore}))
        }).finally(() => setLoadingNext(false))
    }, [data?.hasMore, data?.page, setData, loadingNext])

    return {apps, setData, loadNext, loadingNext, hasMore}
}

export default usePublicApps