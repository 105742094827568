/* eslint-disable no-extend-native */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import Root from './Root'
import reportWebVitals from './reportWebVitals'
import { ToastContainer } from 'react-toastify'
import { createPortal } from 'react-dom'

export const memCache = sessionStorage

declare global {
  interface Array<T> {
    asClass: string
  }
  interface Number {
    between: (min: number, max: number) => number
  }
}

Object.defineProperty(Array.prototype, 'asClass', {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
})

Object.defineProperty(Number.prototype, 'between', {
  configurable: true,
  value(min: number, max: number) {
    return Math.min(Math.max(this, min), max)
  },
})

const rootEl = document.getElementById('root') as HTMLElement
const toastsRoot = document.getElementById('toasts') as HTMLElement
let root;
if (rootEl.hasChildNodes()) {
  root = ReactDOM.hydrateRoot(rootEl,
    <React.StrictMode>
      <Root />
      {createPortal(
        <React.StrictMode>
          <ToastContainer position="bottom-right" className="max-w-max" hideProgressBar limit={1} />
        </React.StrictMode>,
        toastsRoot
      )}
    </React.StrictMode>
    )
} else {
  root = ReactDOM.createRoot(rootEl)
  root.render(
    <React.StrictMode>
      <Root />
      {createPortal(
        <React.StrictMode>
          <ToastContainer position="bottom-right" className="max-w-max" hideProgressBar limit={1} />
        </React.StrictMode>,
        toastsRoot
      )}
    </React.StrictMode>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
