import axios from "./axios";
import { IUser } from "../types/profile";
import { AxiosInstance } from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "../config/firebase";
import { v4 } from "uuid";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { IApp, IAppProfile, IShortApp } from "../types/developer";

class ProfileApi {
    api: AxiosInstance;
    constructor() {
        this.api = axios;
    }

    async getProfile() {
        return this.api.get("/api/users").then(res => res.data as IUser)
    }

    async updateProfile(data: {profile?: IUser["profile"]}) {
        return this.api.patch("/api/users", data)
    }

    async getApprovedApps() {
        return this.api.get("/api/users/approved").then(res => res.data as {id: string, name: string, createdAt: string, icon: string}[])
    }

    async uploadPicture(file: File) {
        const id = v4() + v4()
        if (!file.type.startsWith("image/")) throw new Error("Invalid file type")
        const storageRef = ref(storage, `images/${id}.${file.type.split("/")[1]}`)
        await uploadBytes(storageRef, file)
        const url = await getDownloadURL(storageRef)
        return url
    }

    async updatePassword(password: string, newPassword: string) {
        if (password === newPassword) throw new Error("Your new password cannot be the same as your old password")
        if (!auth.currentUser) {
        throw new Error()
        }
        try {
        await reauthenticateWithCredential(
            auth.currentUser,
            EmailAuthProvider.credential(auth.currentUser.email as string, password),
        )
        } catch (err: any) {
        if (err.code === "auth/wrong-password") {
            throw new Error("Your password is incorrect")
        }
        throw err
        }
        return updatePassword(auth.currentUser, newPassword)
    }

    async updateDeveloperProfile(data: IUser["developerProfile"]) {
        const website = data?.website
        const email = data?.contact?.email
        const phone = data?.contact?.phone
        return this.api.post("/api/users/developer", {website, email, phone})
    }

    async getApps() {
        return this.api.get("/api/users/developer/apps").then(res => res.data as IShortApp[])
    }

    async createApp(name: string) {
        return this.api.post("/api/users/developer/apps", {name}).then(res => res.data as IApp)
    }

    async getApp(id: string) {
        return this.api.get(`/api/users/developer/apps/${id}`).then(res => res.data as IApp)
    }

    async submitApp(id: string) {
        return this.api.post(`/api/users/developer/apps/${id}/review`).then(res => res.data as IApp)
    }

    async updateApp(id: string, data: IAppProfile) {
        return this.api.patch(`/api/users/developer/apps/${id}`, data).then(res => res.data as IApp)
    }
}

const profileApi = new ProfileApi()

export default profileApi