import { AxiosInstance } from "axios";
import axios from "./axios";

class Api {
    api: AxiosInstance;
    constructor() {
        this.api = axios;
    }

    async authorizeApp(oauth: string) {
        return this.api.post("/oauth/app/approve", {oauth}).then(res => window.location.href = res.data)
    }
}

const api = new Api()

export default api