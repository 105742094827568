export const getEmail = (possiblyUsername: string) => {
    let emailForSure = possiblyUsername
    if (!possiblyUsername.includes('@')) {
      emailForSure = `${possiblyUsername}@blankemail.catauth`
    }
    return emailForSure
}

export const getUserName = (email: string) => {
    if (email.endsWith('@blankemail.catauth')) {
      return email.split('@')[0]
    }
    return email
}