import { FC } from 'react'

const Card: FC = () => {
  return (
    <div className="bg-yellow-50 p-4 rounded-xl relative shadow-md flex flex-col items-center gap-2">
      <div className="absolute h-24 w-full bg-yellow-200 top-0 rounded-t-xl" />
      <div className="bg-slate-200 animate-pulse rounded-full w-40 h-40" />
      <div className="w-[60%] h-5 bg-slate-200 animate-pulse mt-2 rounded-lg" />
      <div className="w-[80%] h-3 h-5 bg-slate-200 animate-pulse rounded-lg" />
    </div>
  )
}

const AppCardLoader: FC = () => {
  const cards = Array(20).fill(0)

  return (
    <>
    {cards.map((_, i) => <Card key={i} />)}
    </>
  )
}

export default AppCardLoader
