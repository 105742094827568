import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router";

const AuthLayout: FC<PropsWithChildren> = ({children}) => {
    return (
        <div className="w-full h-full flex items-center grow justify-center">
            {children || <Outlet />}
        </div>
    );
}

export default AuthLayout;
