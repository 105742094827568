import { FC, useEffect, useMemo, useState } from 'react'
import useDeveloperApp from '../../../../hooks/useDeveloperApp'
import { useParams } from 'react-router'
import Icon from '../../../../assets/icons'
import profileApi from '../../../../api/profile'
import toasts from '../../../../components/toasts'
import { IAppProfile } from '../../../../types/developer'

const AppPage: FC = () => {
  const { id } = useParams()
  const { app, setApp, updateApp, submitApp, submitting } = useDeveloperApp(id as string)

  const required = true

  const appProfile = useMemo(
    () => ({
      name: app?.changes?.name || app?.name || '',
      contact: app?.changes?.contact || app?.contact || { email: '', phone: '' },
      shortDescription: app?.changes?.shortDescription || app?.shortDescription || '',
      description: app?.changes?.description || app?.description || '',
      icon: app?.changes?.icon || app?.icon || '',
      background: app?.changes?.background || app?.background || '',
      website: app?.changes?.website || app?.website || '',
      externalLinks: app?.changes?.externalLinks || app?.externalLinks || [],
    }),
    [app]
  )

  const [form, setForm] = useState(appProfile)

  const onUpdate = () => {
    if (submitting) return toasts.error('Please wait for the previous action to complete')
    if (app?.approved && (!form.name || !form.contact?.email || !form.shortDescription || !form.description || !form.icon || !form.background || !form.website)) {
      return toasts.error('Please fill all required fields')
    }
    updateApp(form as IAppProfile)
  }

  const onSubmit = () => {
    if (submitting) return toasts.error('Please wait for the previous action to complete')
    if (!form.name || !form.contact?.email || !form.shortDescription || !form.description || !form.icon || !form.background || !form.website) {
      return toasts.error('Please fill all required fields')
    }
    submitApp()
  }

  const onBackgroundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      profileApi.uploadPicture(file).then((url) => {
        toasts.success('Background uploaded')
        setForm((old) => old && { ...old, background: url })
      })
    }
  }

  const onIconChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      profileApi.uploadPicture(file).then((url) => {
        toasts.success('Icon uploaded')
        setForm((old) => old && { ...old, icon: url })
      })
    }
  }

  useEffect(() => {
    setForm(appProfile)
  }, [appProfile])

  const status = !app?.active ? (app?.approved ? 'Not Active' : app?.changes ? 'In Review' : 'Working') : app?.changes ? 'Pending Changes' : 'Active'
  const statusColor = !app?.active ? (app?.approved ? 'bg-red-500 text-red-50' : 'bg-yellow-400 text-yellow-50') : app?.changes ? 'bg-yellow-500 text-yellow-50' : 'bg-green-500 text-green-50'

  return (
    <div data-loading={!app} className="w-full h-full flex flex-col pt-4">
      <div className="rounded-xl bg-yellow-100 p-4 mx-4 justify-between flex items-center drop-shadow-md">
        <div className="flex items-center gap-4">
          <h1 className="font-semibold text-2xl">App Listing</h1>
          {app && <span className={['text-xs font-medium px-2 py-1 rounded-full', statusColor].asClass}>{status}</span>}
        </div>
        {app?.approved ? (
          <button disabled={submitting} className="button-primary w-max -my-2" onClick={onUpdate}>
            Send Changes for Review
          </button>
        ) : (
          <div className="flex items-center gap-4 -my-2">
            {app?.changes ? (
              <button disabled={submitting} className="button-primary w-max" onClick={onUpdate}>
                Update Submission
              </button>
            ) : (
              <>
                <button disabled={submitting} className="button-primary w-max bg-transparent text-slate-900 border-2 py-1.5 border-slate-900" onClick={onUpdate}>
                  Save Draft
                </button>
                <button disabled={submitting} className="button-primary w-max" onClick={onSubmit}>
                  Submit for Review
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col w-full gap-4 overflow-y-auto pb-8 drop-shadow-lg pt-4 px-4">
        <div
          className="rounded-xl bg-yellow-100 p-4 pt-16 md:p-8 mx-4 relative transition-all"
          style={{
            backgroundImage: form.background ? `url(${form.background})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
          }}
        >
          <label className="absolute right-6 top-6 cursor-pointer">
            <input type="file" onChange={onBackgroundChange} className="hidden" />
            <div className="button-primary py-1 px-2">{!form.background ? 'Upload Background' : 'Change Background'}</div>
          </label>
          <div className="flex items-end gap-4 md:gap-8">
            <label className="w-60 rounded-full bg-yellow-200 overflow-hidden !aspect-square hover:p-2 cursor-pointer transition-all drop-shadow-xl">
              <input type="file" onChange={onIconChange} className="hidden" />
              {form.icon && <img src={form.icon} className="rounded-full" alt={form.name} />}
              {!form.icon && <Icon name="Image" className="p-8 w-full h-full text-yellow-400" />}
            </label>
            <div className="pb-8 flex flex-col gap-3 drop-shadow-xl">
              <input
                type="text"
                placeholder="App name"
                required={required}
                className="input-primary text-left w-full font-semibold text-2xl"
                value={form.name}
                onChange={(e) => setForm((old) => old && { ...old, name: e.target.value })}
              />
              <input
                type="text"
                placeholder="Short Description"
                required={required}
                className="input-primary text-left placeholder:text-slate-600 w-full font-normal"
                value={form.shortDescription}
                onChange={(e) => setForm((old) => old && { ...old, shortDescription: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="rounded-xl bg-yellow-100 p-4 mx-4 justify-between flex items-center">
          <h1 className="font-medium text-xl">App Details</h1>
        </div>
        <div className="flex gap-4 w-full px-4 flex-col md:flex-row">
          <div className="flex flex-col rounded-xl bg-yellow-100 p-4 grow gap-4">
            <h1 className="font-semibold text-xl">External Links</h1>
            <div className="flex flex-col w-full gap-1 text-left">
              <span className="font-medium text-xs">Website</span>
              <input type="text" required={required} className="input-primary text-left w-full" value={form.website} onChange={(e) => setForm((old) => old && { ...old, website: e.target.value })} />
            </div>
          </div>
          <div className="flex flex-col rounded-xl bg-yellow-100 p-4 grow gap-4">
            <h1 className="font-semibold text-xl">Contact Information</h1>
            <div className="flex flex-col w-full gap-1 text-left">
              <span className="font-medium text-xs">Email</span>
              <input
                type="email"
                required={required}
                className="input-primary text-left w-full"
                value={form.contact?.email}
                onChange={(e) => setForm((old) => old && { ...old, contact: { ...old.contact, email: e.target.value } })}
              />
            </div>
            <div className="flex flex-col w-full gap-1 text-left">
              <span className="font-medium text-xs">Phone Number</span>
              <input
                type="tel"
                className="input-primary text-left w-full"
                value={form.contact?.phone}
                onChange={(e) => setForm((old) => old && { ...old, contact: { ...old.contact, phone: e.target.value } })}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col rounded-xl bg-yellow-100 p-4 mx-4 gap-4">
          <h1 className="font-semibold text-xl">Description</h1>
          <textarea
            placeholder="Enter app description"
            required={required}
            className="input-primary placeholder:text-slate-600 font-normal w-full"
            rows={5}
            value={form.description}
            onChange={(e) => setForm((old) => old && { ...old, description: e.target.value })}
          />
        </div>
      </div>
    </div>
  )
}

export default AppPage
