import { FC } from 'react'
import { useNavigate } from 'react-router'
import { IShortApp } from '../../../../types/developer'
import Icon from '../../../../assets/icons'

interface props {
  app: IShortApp
}

const AppCard: FC<props> = ({ app }) => {
  const navigate = useNavigate()
  const status = !app?.active ? (app?.approved ? 'Not Active' : app?.pendingChanges ? "In Review" : 'Working') : app?.pendingChanges ? 'Pending Changes' : 'Active'
  const statusColor = !app.active ? app.approved ? "bg-red-500 text-red-50" : "bg-yellow-400 text-yellow-50" : app.pendingChanges ? "bg-yellow-500 text-yellow-50" : "bg-green-500 text-green-50"

  return (
    <div
      onClick={() => navigate(`./${app.id}`, { relative: 'path' })}
      className="bg-yellow-50 p-4 rounded-xl relative grow shadow-md flex flex-col items-center gap-2 hover:translate-x-1 hover:-translate-y-1 hover:shadow-xl cursor-pointer transition-all"
    >
      <div className="absolute h-20 w-20 right-0 top-0 flex flex-col justify-center z-[10] overflow-hidden">
        <span 
        className={[
          "font-semibold text-sm text-center bg-green-500 w-31 px-5 pb-0.5 -mx-5 rotate-45 -translate-y-[29%] translate-x-[0.35rem]",
          statusColor
        ].asClass}
        >
          {status}
        </span>
      </div>
      <div
       className="absolute h-24 w-full bg-yellow-200 top-0 rounded-t-xl" 
       style={{
            backgroundImage: app.background ? `url(${app.background})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
          }}
       />
      {app.icon && <img src={app.icon} className="rounded-full w-40 h-40 z-[10] drop-shadow-xl bg-yellow-100" alt={app.name} />}
      {!app.icon && (
        <div className="w-40 h-40 rounded-full p-8 bg-yellow-100 z-[10] drop-shadow-xl">
          <Icon name="Image" className="w-full h-full flex items-center text-yellow-400 justify-center" />
        </div>
      )}
      <h2 className="font-semibold text-slate-900 text-xl mt-2">{app.name}</h2>
      <p className="text-slate-600">{app.shortDescription || "---"}</p>
    </div>
  )
}

export default AppCard
