import { FC } from 'react'
import usePublicApps from '../../../hooks/usePublicApps'
import AppCard from './components/AppCard'
import AppCardLoader from './components/AppCardLoader'
import { useLocation } from 'react-router'

const DiscoverAppsPage: FC = () => {
  const location = useLocation().pathname
  const dontShowHeader = location === '/discover-apps'
  const { apps, hasMore, loadNext, loadingNext } = usePublicApps()

  return (
    <div className="w-full h-full flex flex-col py-4">
      {!dontShowHeader && (
        <div className="rounded-xl bg-yellow-100 p-4 mx-4">
          <h1 className="font-semibold text-2xl">Discover Apps</h1>
        </div>
      )}
      {apps?.length === 0 && <div className="text-center text-slate-600 mt-4">No apps found</div>}
      {!apps && <div className="bg-slate-200 mx-8 grow rounded-2xl animate-pulse h-full mt-4" />}
      {!apps?.length && (
        <div className="grid grid-cols-5 gap-4 overflow-y-auto pb-8 pt-4 px-4">
          {apps?.map((app, i) => (
            <AppCard key={app.id} app={app} />
          ))}
          {loadingNext && <AppCardLoader />}
        </div>
      )}
    </div>
  )
}

export default DiscoverAppsPage
