import { FC, useCallback, useEffect, useState } from 'react'
import { getUserName } from '../../utils/userName'
import { IDeveloperProfile, IProfile } from '../../types/profile'
import profileApi from '../../api/profile'
import Icon from '../../assets/icons'
import { useNavigate } from 'react-router'
import useProfileContext from '../../contexts/Profile/hook'
import { signOut } from 'firebase/auth'
import { auth } from '../../config/firebase'
import toasts from '../../components/toasts'

const ProfilePage: FC = () => {
  const { profile, updateProfile, updateDeveloperProfile, approvedApps, updating } = useProfileContext()
  const [form, setForm] = useState<IProfile>(profile?.profile || ({} as IProfile))
  const [developerForm, setDeveloperForm] = useState(profile?.developerProfile || ({} as IDeveloperProfile))

  useEffect(() => {
    setForm(profile?.profile || ({} as IProfile))
    setDeveloperForm(profile?.developerProfile || ({} as IDeveloperProfile))
  }, [profile])

  const userName = getUserName(profile?.email || '')
  const isEmail = userName.includes('@')
  const [changing, setChanging] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (form && !changing) {
        updateProfile({ profile: form })
      }
      return false
    },
    [updateProfile, form, changing]
  )

  const onBecomeDeveloper = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (developerForm && !changing) {
        updateDeveloperProfile(developerForm)
      }
      return false
    },
    [updateDeveloperProfile, developerForm, changing]
  )

  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (changing) return
      if (!e.target.files) return
      const file = e.target.files[0]
      if (!file) return
      setChanging(true)
      profileApi
        .uploadPicture(file)
        .then((url) => {
          setForm((old) => old && { ...old, avatar: url })
        })
        .finally(() => setChanging(false))
    },
    [changing]
  )

  const onPasswordChange = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (changing) return false
      setChanging(true)
      profileApi
        .updatePassword(password, newPassword)
        .then(() => {
          toasts.success('Password updated successfully')
          setPassword('')
          setNewPassword('')
        })
        .finally(() => setChanging(false))
      return false
    },
    [password, newPassword, changing]
  )

  const onExploreApps = useCallback(() => {
    navigate('/discover')
  }, [])

  return (
    <div className="w-full h-full flex flex-col pt-4">
      <div className="rounded-xl bg-yellow-100 p-4 flex items-center drop-shadow-md mx-4">
        <h1 className="font-semibold text-2xl grow">Profile Settings</h1>
        <button className="button-primary w-max -my-2" onClick={() => signOut(auth)}>
          Logout
        </button>
      </div>
      <div className="main-grid-auto min-h-max gap-4 overflow-y-auto pb-8 pt-4 px-4 mx-4">
        <form data-loading={!profile} onSubmit={onSubmit} className="rounded-xl bg-yellow-50 items-center p-4 flex flex-col gap-2">
          <h1 className="font-medium text-lg">Profile Details</h1>
          <label htmlFor="avatar" className="flex flex-col gap-2 cursor-pointer">
            <div className="w-20 h-20 rounded-full bg-yellow-300 p-2 hover:p-1.5 transition-all">
              {form.avatar && <img src={form.avatar} alt="avatar" className="rounded-full w-full h-full" />}
              {!form.avatar && <Icon name="Profile" className="w-16 h-16 text-slate-700" />}
            </div>
            <input type="file" id="avatar" onChange={onFileChange} className="hidden" />
          </label>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Display Name</span>
            <input type="text" className="input-primary text-center" value={form?.name} onChange={(e) => setForm((old) => old && { ...old, name: e.target.value })} />
          </div>
          <input type="submit" value="Submit" className="hidden" />
          <div className="grow" />
          <button className="button-primary w-max mt-2" disabled={updating}>
            Update Profile
          </button>
        </form>
        <form data-loading={!profile} onSubmit={onPasswordChange} className="rounded-xl bg-yellow-50 items-center p-4 flex flex-col gap-2">
          <h1 className="font-medium text-lg">User Details</h1>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">{isEmail ? 'Email' : 'Username'}</span>
            <input type="text" className="input-primary text-center" value={userName} readOnly />
          </div>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Old Password</span>
            <input type="password" autoComplete="off" className="input-primary text-center" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">New Password</span>
            <input type="password" autoComplete="off" className="input-primary text-center" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </div>
          <div className="grow" />
          <input type="submit" value="Submit" className="hidden" />
          <button className="button-primary w-max mt-2" disabled={changing}>
            Update Password
          </button>
        </form>
        <form className="rounded-xl items-center bg-yellow-50 p-4 flex flex-col gap-4">
          <h1 className="font-medium text-lg">Connected Apps</h1>
          {approvedApps?.length === 0 && (
            <>
              <div className="flex flex-col grow items-center justify-center gap-4">
                <span className="text-center text-slate-900">No connected apps</span>
              </div>
              <button onClick={onExploreApps} className="button-primary w-max">
                Explore Apps
              </button>
            </>
          )}
          {approvedApps && approvedApps?.length > 0 && (
            <div className="grid grid-cols-3 gap-2 w-full">
              {approvedApps.map((app, i) => (
                <img key={i} src={app.icon} alt={app.name} className="rounded-xl w-full h-20 object-cover" />
              ))}
            </div>
          )}
        </form>
        <form onSubmit={onBecomeDeveloper} className="rounded-xl bg-yellow-50 items-center p-4 flex flex-col gap-2">
          <h1 className="font-medium text-lg text-slate-900">Developer Profile</h1>
          {!!profile?.developerProfileChanges && <span className="text-xs text-center text-slate-900 bg-orange-200 rounded-full px-2 py-1 font-semibold -mt-1">In Review</span>}
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Website</span>
            <input type="text" className="input-primary text-center" value={developerForm?.website} onChange={(e) => setDeveloperForm((old) => old && { ...old, website: e.target.value })} />
          </div>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Contact Email</span>
            <input
              type="text"
              className="input-primary text-center"
              value={developerForm?.contact?.email}
              onChange={(e) => setDeveloperForm((old) => old && { ...old, contact: { ...old.contact, email: e.target.value } })}
            />
          </div>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Contact Phone</span>
            <input
              type="text"
              className="input-primary text-center"
              value={developerForm?.contact?.phone}
              onChange={(e) => setDeveloperForm((old) => old && { ...old, contact: { ...old.contact, phone: e.target.value } })}
            />
          </div>
          <div className="grow" />
          <button className="button-primary w-max" disabled={updating}>
            {!!profile?.developerProfile ? 'Send For Review' : 'Become a Developer'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default ProfilePage
