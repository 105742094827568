import { signInWithCredential, signInWithEmailAndPassword } from 'firebase/auth'
import { FC, FormEvent, useState } from 'react'
import { auth } from '../../config/firebase'
import { Logo } from '../../assets'
import { getEmail } from '../../utils/userName'

interface props {
  setSignIn: (signIn: boolean) => void
}

const Login: FC<props> = ({ setSignIn }) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [signingUp, setSigningUp] = useState<boolean>(false)

  const onSignup = (e: FormEvent) => {
    e.preventDefault()
    if (!email || !password) {
      return
    }
    if (signingUp) return
    setSigningUp(true)
    const emailForSure = getEmail(email)
    signInWithEmailAndPassword(auth, emailForSure, password).finally(() => setSigningUp(false))
    return false
  }

  return (
    <div className="w-full h-max flex justify-center p-10">
      <form onSubmit={onSignup} className="flex flex-col bg-yellow-100 px-8 py-4 pb-6 rounded-xl gap-2.5 min-w-[512px]">
        <div className="flex items-center justify-start gap-4 mb-2 -left-4 relative">
        <Logo className='w-8 h-8' />
        <h1 className="text-slate-900 font-semibold text-2xl">Sign In</h1>
        </div>
        <input className="px-4 py-2 text-sm bg-yellow-50 rounded-lg outline-none font-medium" type="email" placeholder="Username or email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input
          className="px-4 py-2 text-sm bg-yellow-50 rounded-lg outline-none font-medium mb-4"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="button-primary"
          onClick={onSignup}
          disabled={signingUp}
        >
          Sign In
        </button>
        <input type="submit" value="Submit" className="hidden" />
        <span className="select-none text-center">
          Don't have an account? <span className="cursor-pointer hover:underline font-semibold" onClick={() => setSignIn(false)}>Create</span>
        </span>
      </form>
    </div>
  )
}

export default Login
