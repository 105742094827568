import { FC, PropsWithChildren } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Logo } from '../assets'
import Icon, { IconName } from '../assets/icons'
import useProfileContext from '../contexts/Profile/hook'

interface RouteDef {
  path: string
  name: string
  icon: IconName
}

const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isActive = (path: string) => path === "/" ? location.pathname === path : location.pathname.startsWith(path)
  const profile = useProfileContext()?.profile
  const isDeveloper = !!profile?.hasApps || !!profile?.developerProfile

  const routes: RouteDef[] = [
    {
      path: '/profile',
      name: 'Profile',
      icon: 'Profile',
    },
    {
      path: '/discover',
      name: 'Discover Apps',
      icon: 'Discover',
    },
    ...(isDeveloper
      ? [
          {
            path: '/apps',
            name: 'Apps',
            icon: 'Data',
          },
        ] as const
      : []),
  ] as RouteDef[];

  return (
    <div className="w-full h-full flex">
      <div className="w-16 h-full bg-yellow-100 flex flex-col items-center pt-2 pb-4 gap-2">
        <div className="flex flex-col grow px-2 gap-2">
          {routes.map((route, i) => (
            <button onClick={() => navigate(route.path)} key={i} className={['rounded-xl hover:bg-yellow-200 p-2 transition-colors', isActive(route.path) && 'bg-yellow-300'].asClass}>
              <Icon name={route.icon} className="w-8 h-8 text-slate-900" />
            </button>
          ))}
        </div>
        {/*<button className="rounded-xl hover:bg-slate-300 p-2 transition-colors">
          <Icon name="Code" className="w-8 h-8 text-slate-900" />
  </button>*/}
        <Logo className="w-12 h-12" />
      </div>
      <div className="grow h-full overflow-hidden">{children || <Outlet />}</div>
    </div>
  )
}

export default MainLayout
