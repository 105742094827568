import { FC } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router'
import AuthProvider from '../contexts/Auth'
import OAuth from '../pages/private/OAuth'
import ProfilePage from '../pages/private/Profile'
import Login from '../pages/auth/Login'
import SignUp from '../pages/auth/SignUp'
import PrivateProvider from '../contexts/Private'
import AuthLayout from '../layouts/AuthLayout'
import MainLayout from '../layouts/MainLayout'
import AppsPage from '../pages/private/Apps'
import DiscoverAppsPage from '../pages/private/DiscoverApps'
import ProfileProvider from '../contexts/Profile'
import AppPage from '../pages/private/Apps/App'
import DiscoveredApp from '../pages/private/DiscoverApps/App'
import PublicLayout from '../layouts/PublicLayout'
import AboutPage from '../pages/public/About'

const MainRouter: FC = () => {
  const navigate = useNavigate()
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<PublicLayout />}>
          <Route index element={<AboutPage />} />
          <Route path="discover-apps" element={<DiscoverAppsPage />} />
        </Route>
        <Route path="auth" element={<AuthLayout />}>
          <Route index element={<Login setSignIn={() => navigate('./signup', { relative: 'path' })} />} />
          <Route path="signup" element={<SignUp setSignIn={() => navigate('..', { relative: 'path' })} />} />
        </Route>
        <Route path="*" element={<Outlet />}>
          <Route
            path="*"
            element={
              <PrivateProvider>
                <ProfileProvider>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </ProfileProvider>
              </PrivateProvider>
            }
          >
            <Route path="profile" element={<ProfilePage />} />
            <Route path="apps" element={<Outlet />}>
              <Route index element={<AppsPage />} />
              <Route path=":id" element={<AppPage />} />
            </Route>
            <Route path="discover" element={<Outlet />}>
              <Route index element={<DiscoverAppsPage />} />
              <Route path=":id" element={<DiscoveredApp />} />
            </Route>
            <Route path="oauth" element={<OAuth />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default MainRouter
