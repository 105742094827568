import { FC, SVGProps } from 'react'

import { ReactComponent as Profile } from './profile-circle.svg'
import { ReactComponent as Data } from './data.svg'
import { ReactComponent as Discover } from "./discover-1.svg"
import { ReactComponent as Code } from './code.svg'
import { ReactComponent as AddCircle } from './add-circle.svg'
import { ReactComponent as Image } from './image.svg'
import { ReactComponent as Home } from './home-1.svg'

const iconSet = {
  Profile,
  Data,
  Discover,
  Code,
  AddCircle,
  Image,
  Home
} as const

export type IconName = keyof typeof iconSet

interface props extends SVGProps<SVGSVGElement> {
  name: IconName
}

const Icon: FC<props> = ({ name, ...props }) => {
  const Icon = iconSet[name]
  return <Icon {...props} />
}

export default Icon
