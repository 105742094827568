import { FC, useState } from "react";
import api from "../../api";
import { useSearchParams } from "react-router-dom";

const OAuth: FC = () => {
    const [sp] = useSearchParams()
    const oauth = sp.get("oauth");
    const app = sp.get("app");
    const [authorizing, setAuthorizing] = useState<boolean>(false);

    const onAuthorize = () => {
        if (authorizing) return;
        if (!oauth) return console.error("No OAuth");
        setAuthorizing(true);
        api.authorizeApp(oauth).finally(() => setAuthorizing(false));
    }

    if (!app || !oauth) {
        return <h1>Invalid OAuth</h1>
    }

    return (
        <div>
            <h1>OAuth</h1>
            <button onClick={onAuthorize}>Authorize App</button>
        </div>
    );
}

export default OAuth;