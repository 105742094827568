import { FC } from 'react'
import { IPublicAppShort } from '../../../../types/apps'
import { useNavigate } from 'react-router'

interface props {
  app: IPublicAppShort
}

const AppCard: FC<props> = ({ app }) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(`./${app.id}`, { relative: 'path' })}
      className="bg-yellow-50 p-4 rounded-xl relative shadow-md flex flex-col items-center gap-2 hover:translate-x-1 hover:-translate-y-1 hover:shadow-xl cursor-pointer transition-all"
    >
      <img src={app.icon} className="rounded-full w-40 h-40 z-[10]" alt={app.name} />
      <div className="absolute h-24 w-full bg-yellow-200 top-0" />
      <h2 className="font-semibold text-slate-900 text-xl mt-2">{app.name}</h2>
      <p className="text-slate-600">{app.shortDescription}</p>
    </div>
  )
}

export default AppCard
