import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
import {getStorage} from "firebase/storage"
import {getAnalytics} from "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyBRy8DPWU16OFLckk0bPg6rmP-HTbJGLkQ",
  authDomain: "repka-catauth.firebaseapp.com",
  projectId: "repka-catauth",
  storageBucket: "repka-catauth.appspot.com",
  messagingSenderId: "214681819356",
  appId: "1:214681819356:web:324b297ac280326b52fc19",
  measurementId: "G-XKFBGM5TBE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const storage = getStorage(app)
const analytics = getAnalytics(app);

export {auth, app, storage, analytics}