import { createUserWithEmailAndPassword } from "firebase/auth";
import { FC, FormEvent, useState } from "react";
import { auth } from "../../config/firebase";
import { Logo } from "../../assets";
import { getEmail } from "../../utils/userName";

interface props {
  setSignIn: (signIn: boolean) => void
}

const SignUp: FC<props> = ({setSignIn}) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [signingUp, setSigningUp] = useState<boolean>(false);

    const onSignup = (e: FormEvent) => {
        if (!email || !password) {
            return;
        }
        if (signingUp) return
        const emailForSure = getEmail(email)
        setSigningUp(true);
        createUserWithEmailAndPassword(auth, emailForSure, password).finally(() => setSigningUp(false))
        return false
    }

    return (
    <div className="w-full h-max flex justify-center p-10">
      <form onSubmit={onSignup} className="flex flex-col bg-yellow-100 px-8 py-4 pb-6 rounded-xl gap-2.5 min-w-[512px]">
        <div className="flex items-center justify-start gap-4 mb-2 -left-4 relative">
        <Logo className='w-8 h-8' />
        <h1 className="text-slate-900 font-semibold text-2xl">Sign Up</h1>
        </div>
        <input className="px-4 py-2 text-sm bg-yellow-50 rounded-lg outline-none font-medium" type="email" placeholder="Username or email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <input
          className="px-4 py-2 text-sm bg-yellow-50 rounded-lg outline-none font-medium mb-4"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="px-4 py-2 rounded-lg bg-slate-900 text-yellow-200 font-medium text-lg hover:rounded hover:-translate-y-0.5 hover:translate-x-0.5 transition-all"
          onClick={onSignup}
          disabled={signingUp}
        >
          Sign Up
        </button>
        <input type="submit" value="Submit" className="hidden" />
        <span className="select-none text-center">
          Already have an account? <span className="cursor-pointer hover:underline font-semibold" onClick={() => setSignIn(true)}>Sign In</span>
        </span>
      </form>
    </div>
    );
}

export default SignUp;