import { User } from "firebase/auth";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import authContext from "./context";
import { comeBack } from "../../utils/redirects";

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
              comeBack("authorize")
            }
            setUser(user);
            setLoading(false);
        })

        return () => unsubscribe();
    }, [])



    if (loading) return <div>Loading</div>

    return (
        <authContext.Provider value={{user}}>
            {children}
        </authContext.Provider>
    );
}

export default AuthProvider;