import { FC } from "react";
import usePublicApp from "../../../../hooks/usePublicApp";
import { useParams } from "react-router";

const DiscoveredApp: FC = () => {
    const {id} = useParams()
    const {app} = usePublicApp(id as string)

    return (
    <div data-loading={!app} className="w-full h-full flex flex-col pt-4 gap-4">
      <div className="flex flex-col w-full gap-4 overflow-y-auto pb-8 drop-shadow-lg">
        <div
          className="rounded-xl bg-yellow-100 p-8 pt-16 mx-4 relative"
          style={{
            backgroundImage: app?.background ? `url(${app.background})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
          }}
        >
          <div className="flex items-end gap-8">
            <div className="w-60 rounded-full bg-yellow-200 overflow-hidden !aspect-square drop-shadow-xl">
              <img src={app?.icon} className="rounded-full" alt={app?.name} />
            </div>
            <div className="pb-8 flex flex-col gap-3 drop-shadow-xl">
              <div
                className="input-primary text-left w-60 font-semibold text-2xl"
              >
                {app?.name}
                </div>
                <div
                className="input-primary text-left placeholder:text-slate-600 w-[20rem] font-normal"
                >
                {app?.shortDescription}
                </div>
            </div>
          </div>
        </div>
        <div className="rounded-xl bg-yellow-100 p-4 mx-4 justify-between flex items-center">
          <h1 className="font-medium text-xl">App Details</h1>
        </div>
      </div>
    </div>
    );
}

export default DiscoveredApp;