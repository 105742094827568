import axios from "./axios";
import { AxiosInstance } from "axios";
import { IPublicApp, IPublicAppShort } from "../types/apps";

class AppsApi {
    api: AxiosInstance;
    constructor() {
        this.api = axios;
    }

    async getApps(query?: {page: number}) {
        const sp = new URLSearchParams()
        sp.append("page", query?.page.toString() || "1")
        return this.api.get("/api/apps?" + sp.toString()).then(res => res.data as {
            data: IPublicAppShort[],
            page: number,
            hasMore: boolean
        })
    }

    async getApp(id: string) {
        return this.api.get(`/api/apps/${id}`).then(res => res.data as IPublicApp)
    }
    
}

const appsApi = new AppsApi()

export default appsApi