import { FC, PropsWithChildren, useEffect } from "react";
import privateContext from "./context";
import { redirectAndComeBack } from "../../utils/redirects";
import useAuth from "../Auth/hook";

const PrivateProvider: FC<PropsWithChildren> = ({ children }) => {
    const {user} = useAuth();

    useEffect(() => {
        if (!user) {
            redirectAndComeBack("/auth", "authorize")
        }
    }, [user])

    if (!user) return <h1>Loading...</h1>

    return (
        <privateContext.Provider value={{user}}>
            {children}
        </privateContext.Provider>
    );
}

export default PrivateProvider;