import { useCallback, useState } from "react"
import profileApi from "../api/profile"
import useApiSource from "./useApiSource"
import toasts from "../components/toasts"
import { IAppProfile } from "../types/developer"

const useDeveloperApp = (id: string) => {
    const {data, setData} = useApiSource(profileApi.getApp.bind(profileApi, id))
    const [submitting, setSubmitting] = useState(false)

    const submitApp = useCallback(() => {
        if (submitting) return
        setSubmitting(true)
        profileApi.submitApp(id).then((updated) => {
            toasts.success("App submitted for review")
            setData(old => old && ({...old, ...updated}))
        }).finally(() => setSubmitting(false))
    }, [id, submitting, setData])

    const updateApp = useCallback((data: IAppProfile) => {
        if (submitting) return
        setSubmitting(true)
        profileApi.updateApp(id, data).then((updated) => {
            toasts.success("App updated")
            setData(old => old && ({...old, ...updated}))
        }).finally(() => setSubmitting(false))
    }, [id, submitting, setData])

    return {
        app: data,
        setApp: setData,
        submitApp,
        updateApp,
        submitting,
    }
}

export default useDeveloperApp