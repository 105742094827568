import { FC } from 'react'
import { useNavigate } from 'react-router'

const AboutPage: FC = () => {
    const navigate = useNavigate()
  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="w-full min-h-full flex flex-col px-8 overflow-y-auto">
        <div className="min-h-full pt-32 grow flex flex-col justify-end pb-16 gap-2">
          <h3 className="text-2xl">It's been the 21st century for 24 years now...</h3>
          <h1 className="text-6xl font-medium text-slate-900">
            Let's kickstart <span className="text-yellow-400">Catholic Software Development</span>. <br />
            Community driven and aimed. It's about time.
          </h1>
          <p className="text-slate-600 text-lg w-[80rem] max-w-full mt-2">
            We are a community of Catholic developers, designers, and creators who are passionate about using our skills to build tools and resources that help the Church and Her people grow.
          </p>
        </div>
      </div>
      <div className="p-8 pb-32 flex flex-col">
        <h1 className="font-medium text-4xl">The idea behind it</h1>
        <p className="text-slate-600 text-lg mt-4">
            After years of being active in the church and later working in the tech industry, we realized that the technological toolset of the Catholic Church is lacking to say the least. We have led the educational efforts for millenia, but in this front, we are - as the haters would say - old-fashioned.
        </p>
        <p className="text-slate-600 text-lg mt-2">
            On one hand, there are a lot of local tools - sites to manage the parish, apps to help the youth group, etc. - but they are all isolated and not connected - from a personal example - try to look for sunday masses in Japan. So there are developers.
        </p>
        <p className="text-slate-600 text-lg mt-2">
            Then there are the global tools - the Vatican's website, the YouCat app, etc. - let's not even get started on the Vatican's official website.
        </p>
        <h1 className="font-medium text-4xl mt-8">The solution</h1>
        <p className="text-slate-600 text-lg mt-4">
            We have a unique marketing advantage, not easily replicated - a top-down 4-level hierarchical structure that can be used to spread the word about the tools we create.
        </p>
        <p className="text-slate-600 text-lg mt-2">
            The users are obvious, the developers are there, and the Church is in need. This is the missing link.
        </p>
        <h1 className="font-medium text-4xl mt-8">One more point</h1>
        <p className="text-slate-600 text-lg mt-4">
            We are not just about creating new tools. We are also about listing the existing ones. We want to be the go-to place for the Church to find the tools she needs.   
        </p>
        <p className="text-slate-600 text-lg mt-2">
            If there is a tool, we want it here. We ideally want one solution for a problem, not 10 - talk to each other, make it as global as possible.
        </p>
        <h1 className="font-medium text-4xl mt-8">Interested?</h1>
        <p className="text-slate-600 text-lg mt-4">
            Are you a developer, designer, or creator? Just sign up and start building or just list your existing apps.
        </p>
        <p className="text-slate-600 text-lg mt-2">
            If you're not and you like the idea, help by joining up and spreading the word.
        </p>
        <button className="button-primary self-center w-max mt-8" onClick={() => navigate("/profile")}>Join Up</button>
      </div>
      <p className="p-8">
        Oh, and I'm <a href="https://repka.dev/" target="_blank" rel="noreferrer" className="hover:underline text-emerald-700">Martin</a>. I've been to Japan and got frustrated. If you have any questions, my name is clickable. So let's go.
      </p>
    </div>
  )
}

export default AboutPage
