import { FC, useState } from 'react'
import useDeveloperApps from '../../../hooks/useDeveloperApps'
import AppCard from './components/AppCard'
import Icon from '../../../assets/icons'
import Modal from '../../../components/Modal'
import profileApi from '../../../api/profile'
import { useNavigate } from 'react-router'
import toasts from '../../../components/toasts'

const AppsPage: FC = () => {
  const { apps } = useDeveloperApps()
  const navigate = useNavigate()
  const [create, setCreate] = useState(false)
  const [creating, setCreating] = useState(false)
  const [name, setName] = useState('')

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!name) return
    if (creating) return
    setCreating(true)
    profileApi.createApp(name).then((app) => {
      toasts.success('App created')
      navigate(`./${app.id}`, { relative: "path" })
    }).finally(() => setCreating(false))
  }

  return (
    <div className="w-full h-full flex flex-col pt-4">
      <Modal open={create} close={() => setCreate(false)}>
        <form onSubmit={onSubmit} className="rounded-xl bg-yellow-50 items-center p-4 flex flex-col gap-2 min-w-[16rem]">
          <h1 className="font-medium text-lg">New App</h1>
          <div className="flex flex-col w-full gap-1 text-center">
            <span className="font-medium text-xs">Name</span>
            <input type="text" className="input-primary text-center" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <input type="submit" value="Submit" className="hidden" />
          <div className="grow" />
          <button className="button-primary w-max mt-2" disabled={creating}>Create</button>
        </form>
      </Modal>
      <div className="rounded-xl bg-yellow-100 p-4 mx-4 drop-shadow-md">
        <h1 className="font-semibold text-2xl">Your Apps</h1>
      </div>
      {!apps && <div className="bg-slate-200 mx-8 grow rounded-2xl animate-pulse h-full mt-4" />}
      {!!apps && (
        <div className="main-grid-auto min-h-max gap-4 overflow-y-auto pb-8 pt-4 px-4 mx-4">
          {apps?.map((app, i) => (
            <AppCard key={app.id} app={app} />
          ))}
          <div onClick={() => setCreate(true)} className="bg-yellow-50 p-4 rounded-xl shadow-md flex flex-col items-center gap-2 hover:translate-x-1 hover:-translate-y-1 hover:shadow-xl cursor-pointer transition-all">
            <Icon name="AddCircle" className="rounded-full w-40 h-40 bg-yellow-100 text-yellow-400 drop-shadow-xl" />
            <h2 className="font-semibold text-slate-900 text-xl mt-2">Create a New App</h2>
            <p className="text-slate-600"></p>
          </div>
        </div>
      )}
    </div>
  )
}

export default AppsPage
