import { FC, PropsWithChildren } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { Logo } from '../assets'
import Icon, { IconName } from '../assets/icons'
import useAuth from '../contexts/Auth/hook'

interface RouteDef {
  path: string
  name: string
  icon: IconName
}

const PublicLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {user} = useAuth()
  const isActive = (path: string) => path === "/" ? location.pathname === path : location.pathname.startsWith(path)

  const routes: RouteDef[] = [
    {
      path: '/',
      name: 'About',
      icon: 'Home',
    },
    {
      path: '/discover-apps',
      name: 'Discover Apps',
      icon: 'Discover',
    }
  ] as RouteDef[];

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="grow flex items-center pb-2 gap-2 sticky top-0 left-8 right-8 pt-6 mx-8 bg-slate-100">
        <div className="flex grow px-2 gap-4">
          <Logo className="w-12 h-12" />
          {routes.map((route, i) => (
            <button onClick={() => navigate(route.path)} key={i} className={['rounded-xl flex items-center gap-2 hover:bg-yellow-200 p-2 transition-colors', isActive(route.path) && 'bg-yellow-300'].asClass}>
              <Icon name={route.icon} className="w-8 h-8 text-slate-900" />
              <span>{route.name}</span>
            </button>
          ))}
        </div>
        <div className="flex gap-4 px-2">
          {
            user ? (
              <button className="button-primary" onClick={() => navigate('/profile')}>
                Manage Your Profile
              </button>
            ) : (
              <button className="button-primary" onClick={() => navigate('/profile')}>
                Auth
              </button>
            )
          }
        </div>
        {/*<button className="rounded-xl hover:bg-slate-300 p-2 transition-colors">
          <Icon name="Code" className="w-8 h-8 text-slate-900" />
  </button>*/}
      </div>
      <div className="grow h-full overflow-hidden">{children || <Outlet />}</div>
    </div>
  )
}

export default PublicLayout
