import { useCallback, useEffect, useState } from "react"

const useApiSource = <Params extends unknown[] = unknown[], Res = unknown>(query: (...args: Params) => (Res | Promise<Res>), ...args: Params) => {
    const [data, setData] = useState<Res>()
    const getter = useCallback(async () => {
        return query(...args)
    }, [...args])

    useEffect(() => {
        getter().then(setData)
    }, [getter])

    return {data, setData}
}

export default useApiSource
