import {default as _axios} from "axios";
import { getIdToken } from "firebase/auth";
import { auth } from "../config/firebase";

const axios = _axios.create({
    baseURL: process.env.NODE_ENV  === "development" ? "http://localhost:5001/repka-catauth/us-central1" : "https://api.cathoport.com"
})

axios.interceptors.request.use(async config => {
    if (!auth.currentUser) return config
    const token = await getIdToken(auth.currentUser)
    config.headers.setAuthorization(`Bearer ${token}`)
    return config
})

axios.interceptors.response.use(response => response, error => {
    if (error.response?.status === 302) {
        window.location.href = error.response.data.redirect
    }
    return Promise.reject(error)
})

export default axios
