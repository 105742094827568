import { useCallback, useState } from "react"

const useAsyncCallback = <R = unknown, T extends (...args: any[]) => Promise<R> = any>(callback: T, deps: any[] = []) => {
    const [loading, setLoading] = useState(false)
    const cb = useCallback(async (...args: Parameters<T>) => {
        setLoading(true)
        let encounteredError = false
        let data;
        try {
            data = await callback(...args)
        } catch (e) {
            encounteredError = true;
        } finally {
            setLoading(false)
        }
        if (encounteredError) throw encounteredError
        return data
    }, [...deps])

    return [loading, cb] as const
}

export default useAsyncCallback
